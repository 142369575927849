/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Page } from '../models/page';
import { PictoMode } from '../models/picto-mode';
import { Pictogram } from '../models/pictogram';
import { PictogramData } from '../models/pictogram-data';
import { SortOrder } from '../models/sort-order';
import { UpdatePictogramDto } from '../models/update-pictogram-dto';

@Injectable({ providedIn: 'root' })
export class PictogramsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pagePictograms()` */
  static readonly PagePictogramsPath = '/pictograms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pagePictograms()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagePictograms$Response(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      mode?: PictoMode;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<Pictogram>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, PictogramsService.PagePictogramsPath, 'get');
    if (params) {
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('mode', params.mode, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<Pictogram>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pagePictograms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagePictograms(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      mode?: PictoMode;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<Pictogram>;
}> {
    return this.pagePictograms$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<Pictogram>;
}>): Page & {
'result': Array<Pictogram>;
} => r.body)
    );
  }

  /** Path part for operation `uploadPictograms()` */
  static readonly UploadPictogramsPath = '/pictograms';

  /**
   * Only supports files with mimetype `image/*` and allows 20 images at a time
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadPictograms()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadPictograms$Response(
    params: {
      body: {
'pictograms': Array<Blob>;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PictogramsService.UploadPictogramsPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Only supports files with mimetype `image/*` and allows 20 images at a time
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadPictograms$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadPictograms(
    params: {
      body: {
'pictograms': Array<Blob>;
}
    },
    context?: HttpContext
  ): Observable<void> {
    return this.uploadPictograms$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removePictogram()` */
  static readonly RemovePictogramPath = '/pictograms/{pictogramId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removePictogram()` instead.
   *
   * This method doesn't expect any request body.
   */
  removePictogram$Response(
    params: {
      pictogramId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PictogramsService.RemovePictogramPath, 'delete');
    if (params) {
      rb.path('pictogramId', params.pictogramId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removePictogram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removePictogram(
    params: {
      pictogramId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.removePictogram$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updatePictogram()` */
  static readonly UpdatePictogramPath = '/pictograms/{pictogramId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePictogram()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePictogram$Response(
    params: {
      pictogramId: number;
      body: UpdatePictogramDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Pictogram>> {
    const rb = new RequestBuilder(this.rootUrl, PictogramsService.UpdatePictogramPath, 'patch');
    if (params) {
      rb.path('pictogramId', params.pictogramId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Pictogram>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePictogram$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePictogram(
    params: {
      pictogramId: number;
      body: UpdatePictogramDto
    },
    context?: HttpContext
  ): Observable<Pictogram> {
    return this.updatePictogram$Response(params, context).pipe(
      map((r: StrictHttpResponse<Pictogram>): Pictogram => r.body)
    );
  }

  /** Path part for operation `sendPictogram()` */
  static readonly SendPictogramPath = '/pictograms/{pictogramId}/content';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendPictogram()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendPictogram$Response(
    params: {
      pictogramId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, PictogramsService.SendPictogramPath, 'get');
    if (params) {
      rb.path('pictogramId', params.pictogramId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'image/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendPictogram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendPictogram(
    params: {
      pictogramId: number;
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.sendPictogram$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `pictogramBase64()` */
  static readonly PictogramBase64Path = '/pictograms/{pictogramId}/base64';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pictogramBase64()` instead.
   *
   * This method doesn't expect any request body.
   */
  pictogramBase64$Response(
    params: {
      pictogramId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PictogramData>> {
    const rb = new RequestBuilder(this.rootUrl, PictogramsService.PictogramBase64Path, 'get');
    if (params) {
      rb.path('pictogramId', params.pictogramId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PictogramData>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pictogramBase64$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pictogramBase64(
    params: {
      pictogramId: number;
    },
    context?: HttpContext
  ): Observable<PictogramData> {
    return this.pictogramBase64$Response(params, context).pipe(
      map((r: StrictHttpResponse<PictogramData>): PictogramData => r.body)
    );
  }

  /** Path part for operation `replacePictogram()` */
  static readonly ReplacePictogramPath = '/pictograms/{pictogramId}/replace';

  /**
   * Only supports files with mimetype `image/*`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replacePictogram()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  replacePictogram$Response(
    params: {
      pictogramId: number;
      body: {
'pictogram': Blob;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PictogramsService.ReplacePictogramPath, 'put');
    if (params) {
      rb.path('pictogramId', params.pictogramId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Only supports files with mimetype `image/*`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `replacePictogram$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  replacePictogram(
    params: {
      pictogramId: number;
      body: {
'pictogram': Blob;
}
    },
    context?: HttpContext
  ): Observable<void> {
    return this.replacePictogram$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
