/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


@Injectable({ providedIn: 'root' })
export class AssetsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendAsset()` */
  static readonly SendAssetPath = '/assets/{assetUrl}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendAsset()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendAsset$Response(
    params: {
      assetUrl: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, AssetsService.SendAssetPath, 'get');
    if (params) {
      rb.path('assetUrl', params.assetUrl, {});
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendAsset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendAsset(
    params: {
      assetUrl: string;
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.sendAsset$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `sendPublicAsset()` */
  static readonly SendPublicAssetPath = '/public-assets/{assetUrl}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendPublicAsset()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendPublicAsset$Response(
    params: {
      assetUrl: string;
      signature: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, AssetsService.SendPublicAssetPath, 'get');
    if (params) {
      rb.path('assetUrl', params.assetUrl, {});
      rb.query('signature', params.signature, {});
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendPublicAsset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendPublicAsset(
    params: {
      assetUrl: string;
      signature: string;
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.sendPublicAsset$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
