import { environment } from '@environments/environment';
import { HotToastService } from '@ngneat/hot-toast';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthState } from '../auth.state';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private toast: HotToastService,
    private authState: AuthState,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.cms)) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      tap({
        next: (event: HttpEvent<any>) => {},
        error: (error: any) => {
          console.warn('error', error);
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401 && !error.url.endsWith('/auth/signin')) {
              this.logoutMessage();
              this.authState.logout();
            }
          }
        },
      }),
    );
  }

  logoutMessage = () =>
    this.toast.info('Sie müssen sich erneut einloggen um fortzufahren.', {
      duration: 6000,
      id: 'logout_error',
    });
}
