/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MarkNewsReadDto } from '../models/mark-news-read-dto';
import { UserNews } from '../models/user-news';

@Injectable({ providedIn: 'root' })
export class NewsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `findAll()` */
  static readonly FindAllPath = '/news';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserNews>> {
    const rb = new RequestBuilder(this.rootUrl, NewsService.FindAllPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserNews>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll(
    params?: {
    },
    context?: HttpContext
  ): Observable<UserNews> {
    return this.findAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserNews>): UserNews => r.body)
    );
  }

  /** Path part for operation `findAllPaged()` */
  static readonly FindAllPagedPath = '/news/page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllPaged()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllPaged$Response(
    params?: {
      page?: number;
      size?: number;
      searchQueries?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserNews>> {
    const rb = new RequestBuilder(this.rootUrl, NewsService.FindAllPagedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQueries', params.searchQueries, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserNews>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllPaged$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllPaged(
    params?: {
      page?: number;
      size?: number;
      searchQueries?: Array<string>;
    },
    context?: HttpContext
  ): Observable<UserNews> {
    return this.findAllPaged$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserNews>): UserNews => r.body)
    );
  }

  /** Path part for operation `markNewsRead()` */
  static readonly MarkNewsReadPath = '/news/read';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markNewsRead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markNewsRead$Response(
    params: {
      body: MarkNewsReadDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, NewsService.MarkNewsReadPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markNewsRead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markNewsRead(
    params: {
      body: MarkNewsReadDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.markNewsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `markAllNewsRead()` */
  static readonly MarkAllNewsReadPath = '/news/read/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markAllNewsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  markAllNewsRead$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, NewsService.MarkAllNewsReadPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markAllNewsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markAllNewsRead(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.markAllNewsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
