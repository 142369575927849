/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Auth } from '../models/auth';
import { ChangePasswordDto } from '../models/change-password-dto';
import { DeleteAccountConfirmationDto } from '../models/delete-account-confirmation-dto';
import { LoginDto } from '../models/login-dto';
import { RequestResetPasswordDto } from '../models/request-reset-password-dto';
import { ResetPasswordDto } from '../models/reset-password-dto';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `login()` */
  static readonly LoginPath = '/auth/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login$Response(
    params: {
      body: LoginDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Auth>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.LoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Auth>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `login$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login(
    params: {
      body: LoginDto
    },
    context?: HttpContext
  ): Observable<Auth> {
    return this.login$Response(params, context).pipe(
      map((r: StrictHttpResponse<Auth>): Auth => r.body)
    );
  }

  /** Path part for operation `refreshAccessToken()` */
  static readonly RefreshAccessTokenPath = '/auth/refreshAccessToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refreshAccessToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshAccessToken$Response(
    params: {
      refreshToken: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Auth>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.RefreshAccessTokenPath, 'post');
    if (params) {
      rb.query('refreshToken', params.refreshToken, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Auth>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `refreshAccessToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshAccessToken(
    params: {
      refreshToken: string;
    },
    context?: HttpContext
  ): Observable<Auth> {
    return this.refreshAccessToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<Auth>): Auth => r.body)
    );
  }

  /** Path part for operation `changePassword()` */
  static readonly ChangePasswordPath = '/auth/password/change';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword$Response(
    params: {
      body: ChangePasswordDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.ChangePasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword(
    params: {
      body: ChangePasswordDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.changePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `passwordResetRequest()` */
  static readonly PasswordResetRequestPath = '/auth/password/resetRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passwordResetRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passwordResetRequest$Response(
    params: {
      body: RequestResetPasswordDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.PasswordResetRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passwordResetRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passwordResetRequest(
    params: {
      body: RequestResetPasswordDto
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.passwordResetRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `passwordReset()` */
  static readonly PasswordResetPath = '/auth/password/reset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passwordReset()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passwordReset$Response(
    params: {
      body: ResetPasswordDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.PasswordResetPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passwordReset$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passwordReset(
    params: {
      body: ResetPasswordDto
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.passwordReset$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `deleteAccount()` */
  static readonly DeleteAccountPath = '/auth/deleteAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteAccount$Response(
    params: {
      body: DeleteAccountConfirmationDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.DeleteAccountPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteAccount(
    params: {
      body: DeleteAccountConfirmationDto
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.deleteAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
