/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AuthService } from './services/auth.service';
import { AssetsService } from './services/assets.service';
import { ImagesService } from './services/images.service';
import { TasksService } from './services/tasks.service';
import { AdminService } from './services/admin.service';
import { ChecklistTemplatesService } from './services/checklist-templates.service';
import { ContactService } from './services/contact.service';
import { InspectionsService } from './services/inspections.service';
import { OpenInspectionsService } from './services/open-inspections.service';
import { ManagersService } from './services/managers.service';
import { NewsService } from './services/news.service';
import { OrdersService } from './services/orders.service';
import { PropertiesService } from './services/properties.service';
import { PropertyAttachmentService } from './services/property-attachment.service';
import { UsersService } from './services/users.service';
import { TimeReportService } from './services/time-report.service';
import { PictogramsService } from './services/pictograms.service';
import { LookupService } from './services/lookup.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AuthService,
    AssetsService,
    ImagesService,
    TasksService,
    AdminService,
    ChecklistTemplatesService,
    ContactService,
    InspectionsService,
    OpenInspectionsService,
    ManagersService,
    NewsService,
    OrdersService,
    PropertiesService,
    PropertyAttachmentService,
    UsersService,
    TimeReportService,
    PictogramsService,
    LookupService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
