/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NewAwSlides } from '../models/new-aw-slides';

@Injectable({
  providedIn: 'root',
})
export class AwSlidesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation awSlidesGet
   */
  static readonly AwSlidesGetPath = '/aw-slides';

  /**
   * Find all the aw-slides's records
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awSlidesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  awSlidesGet$Response(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwSlidesService.AwSlidesGetPath, 'get');
    if (params) {
      rb.query('_limit', params['_limit'], {});
      rb.query('_sort', params['_sort'], {});
      rb.query('_start', params['_start'], {});
      rb.query('=', params['='], {});
      rb.query('_ne', params['_ne'], {});
      rb.query('_lt', params['_lt'], {});
      rb.query('_lte', params['_lte'], {});
      rb.query('_gt', params['_gt'], {});
      rb.query('_gte', params['_gte'], {});
      rb.query('_contains', params['_contains'], {});
      rb.query('_containss', params['_containss'], {});
      rb.query('_in', params['_in'], {});
      rb.query('_nin', params['_nin'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Find all the aw-slides's records
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `awSlidesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  awSlidesGet(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<{
'foo'?: string;
}> {

    return this.awSlidesGet$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation awSlidesPost
   */
  static readonly AwSlidesPostPath = '/aw-slides';

  /**
   * Create a new aw-slides record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awSlidesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awSlidesPost$Response(params: {
    body: NewAwSlides
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwSlidesService.AwSlidesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Create a new aw-slides record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `awSlidesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awSlidesPost(params: {
    body: NewAwSlides
  }): Observable<{
'foo'?: string;
}> {

    return this.awSlidesPost$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation awSlidesCountGet
   */
  static readonly AwSlidesCountGetPath = '/aw-slides/count';

  /**
   * Retrieve the number of aw-slides documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awSlidesCountGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  awSlidesCountGet$Response(params?: {
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwSlidesService.AwSlidesCountGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Retrieve the number of aw-slides documents
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `awSlidesCountGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  awSlidesCountGet(params?: {
  }): Observable<{
'foo'?: string;
}> {

    return this.awSlidesCountGet$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation awSlidesIdGet
   */
  static readonly AwSlidesIdGetPath = '/aw-slides/{id}';

  /**
   * Find one aw-slides record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awSlidesIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  awSlidesIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwSlidesService.AwSlidesIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Find one aw-slides record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `awSlidesIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  awSlidesIdGet(params: {
    id: string;
  }): Observable<{
'foo'?: string;
}> {

    return this.awSlidesIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation awSlidesIdPut
   */
  static readonly AwSlidesIdPutPath = '/aw-slides/{id}';

  /**
   * Update a single aw-slides record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awSlidesIdPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awSlidesIdPut$Response(params: {
    id: string;
    body: NewAwSlides
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwSlidesService.AwSlidesIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Update a single aw-slides record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `awSlidesIdPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awSlidesIdPut(params: {
    id: string;
    body: NewAwSlides
  }): Observable<{
'foo'?: string;
}> {

    return this.awSlidesIdPut$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation awSlidesIdDelete
   */
  static readonly AwSlidesIdDeletePath = '/aw-slides/{id}';

  /**
   * Delete a single aw-slides record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awSlidesIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  awSlidesIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, AwSlidesService.AwSlidesIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Delete a single aw-slides record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `awSlidesIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  awSlidesIdDelete(params: {
    id: string;
  }): Observable<number> {

    return this.awSlidesIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
