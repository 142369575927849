import { QueryPriorities } from './priority.actions';
import { Priority } from './../../types/types';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LookupService } from '@api/services';

export interface PriorityStateModel {
  priorities: Priority[];
}
@State<PriorityStateModel>({
  name: 'priority',
  defaults: {
    priorities: [],
  },
})
@Injectable()
export class PriorityState {
  @Selector()
  static priorities(state: PriorityStateModel) {
    return state.priorities;
  }

  constructor(private lookupService: LookupService) {}

  @Action(QueryPriorities)
  QueryPriorities(ctx: StateContext<PriorityStateModel>) {
    return this.lookupService
      .priorities()
      .pipe(tap((priorities) => ctx.patchState({ priorities })));
  }
}
