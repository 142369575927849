import { Category } from './../../types/types';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { QueryCategories } from './category.actions';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LookupService } from '@api/services';

export interface CategoryStateModel {
  categories: Category[];
}

@State<CategoryStateModel>({
  name: 'category',
  defaults: {
    categories: [],
  },
})
@Injectable()
export class CategoryState {
  @Selector()
  static categories(state: CategoryStateModel) {
    return state.categories;
  }

  constructor(private lookupService: LookupService) {}

  @Action(QueryCategories)
  queryCategories(ctx: StateContext<CategoryStateModel>) {
    return this.lookupService
      .categories()
      .pipe(tap((categories) => ctx.patchState({ categories })));
  }
}
