import { APP_STATES } from './app.state';
import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { StateReset } from 'ngxs-reset-plugin';

export class ResetState {
  static readonly type = '[Logout] ResetState';
}

@State<void>({
  name: 'logout',
})
@Injectable()
export class LogoutState {
  @Action(ResetState)
  resetState(ctx: StateContext<void>) {
    return ctx.dispatch([new StateReset(...APP_STATES)]);
  }
}
