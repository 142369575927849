import { LogoutState } from './logout.state';
import { APP_STATES } from './app.state';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { IonicModule } from '@ionic/angular';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { environment } from '@environments/environment';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    NgxsModule.forRoot([...APP_STATES, LogoutState], {
      developmentMode: !environment.production,
    }),
    NgxsResetPluginModule.forRoot(),
    NgxsDispatchPluginModule.forRoot(),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsStoragePluginModule.forRoot(),
  ],
  exports: [NgxsModule],
})
export class NgxsStoreModule {}
