/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NewAwPremiumPartner } from '../models/new-aw-premium-partner';

@Injectable({
  providedIn: 'root',
})
export class AwPremiumPartnerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation awPremiumPartnersGet
   */
  static readonly AwPremiumPartnersGetPath = '/aw-premium-partners';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awPremiumPartnersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  awPremiumPartnersGet$Response(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwPremiumPartnerService.AwPremiumPartnersGetPath, 'get');
    if (params) {
      rb.query('_limit', params['_limit'], {});
      rb.query('_sort', params['_sort'], {});
      rb.query('_start', params['_start'], {});
      rb.query('=', params['='], {});
      rb.query('_ne', params['_ne'], {});
      rb.query('_lt', params['_lt'], {});
      rb.query('_lte', params['_lte'], {});
      rb.query('_gt', params['_gt'], {});
      rb.query('_gte', params['_gte'], {});
      rb.query('_contains', params['_contains'], {});
      rb.query('_containss', params['_containss'], {});
      rb.query('_in', params['_in'], {});
      rb.query('_nin', params['_nin'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `awPremiumPartnersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  awPremiumPartnersGet(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<{
'foo'?: string;
}> {

    return this.awPremiumPartnersGet$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation awPremiumPartnersPost
   */
  static readonly AwPremiumPartnersPostPath = '/aw-premium-partners';

  /**
   * Create a new record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awPremiumPartnersPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awPremiumPartnersPost$Response(params: {
    body: NewAwPremiumPartner
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwPremiumPartnerService.AwPremiumPartnersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Create a new record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `awPremiumPartnersPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awPremiumPartnersPost(params: {
    body: NewAwPremiumPartner
  }): Observable<{
'foo'?: string;
}> {

    return this.awPremiumPartnersPost$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation awPremiumPartnersCountGet
   */
  static readonly AwPremiumPartnersCountGetPath = '/aw-premium-partners/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awPremiumPartnersCountGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  awPremiumPartnersCountGet$Response(params?: {
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwPremiumPartnerService.AwPremiumPartnersCountGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `awPremiumPartnersCountGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  awPremiumPartnersCountGet(params?: {
  }): Observable<{
'foo'?: string;
}> {

    return this.awPremiumPartnersCountGet$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation awPremiumPartnersIdGet
   */
  static readonly AwPremiumPartnersIdGetPath = '/aw-premium-partners/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awPremiumPartnersIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  awPremiumPartnersIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwPremiumPartnerService.AwPremiumPartnersIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `awPremiumPartnersIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  awPremiumPartnersIdGet(params: {
    id: string;
  }): Observable<{
'foo'?: string;
}> {

    return this.awPremiumPartnersIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation awPremiumPartnersIdPut
   */
  static readonly AwPremiumPartnersIdPutPath = '/aw-premium-partners/{id}';

  /**
   * Update a record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awPremiumPartnersIdPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awPremiumPartnersIdPut$Response(params: {
    id: string;
    body: NewAwPremiumPartner
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwPremiumPartnerService.AwPremiumPartnersIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Update a record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `awPremiumPartnersIdPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awPremiumPartnersIdPut(params: {
    id: string;
    body: NewAwPremiumPartner
  }): Observable<{
'foo'?: string;
}> {

    return this.awPremiumPartnersIdPut$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation awPremiumPartnersIdDelete
   */
  static readonly AwPremiumPartnersIdDeletePath = '/aw-premium-partners/{id}';

  /**
   * Delete a record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awPremiumPartnersIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  awPremiumPartnersIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, AwPremiumPartnerService.AwPremiumPartnersIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Delete a record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `awPremiumPartnersIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  awPremiumPartnersIdDelete(params: {
    id: string;
  }): Observable<number> {

    return this.awPremiumPartnersIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
