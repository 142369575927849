/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { PropertyTimeReport } from '../models/property-time-report';
import { UserTimeReport } from '../models/user-time-report';

@Injectable({ providedIn: 'root' })
export class TimeReportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `generateUserTimeReport()` */
  static readonly GenerateUserTimeReportPath = '/timeReport/users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateUserTimeReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateUserTimeReport$Response(
    params: {
      userId: number;
      body: UserTimeReport
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, TimeReportService.GenerateUserTimeReportPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/pdf', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateUserTimeReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateUserTimeReport(
    params: {
      userId: number;
      body: UserTimeReport
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.generateUserTimeReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `generatePropertyTimeReport()` */
  static readonly GeneratePropertyTimeReportPath = '/timeReport/properties/{propertyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generatePropertyTimeReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generatePropertyTimeReport$Response(
    params: {
      propertyId: number;
      body: PropertyTimeReport
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, TimeReportService.GeneratePropertyTimeReportPath, 'post');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/pdf', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generatePropertyTimeReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generatePropertyTimeReport(
    params: {
      propertyId: number;
      body: PropertyTimeReport
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.generatePropertyTimeReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
