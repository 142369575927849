/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AgbService } from './services/agb.service';
import { AwBetriebssicherheitPageService } from './services/aw-betriebssicherheit-page.service';
import { AwCallToActionService } from './services/aw-call-to-action.service';
import { AwContactService } from './services/aw-contact.service';
import { AwDownloadPageService } from './services/aw-download-page.service';
import { AwDownloadService } from './services/aw-download.service';
import { AwFaqService } from './services/aw-faq.service';
import { AwFunktionenService } from './services/aw-funktionen.service';
import { AwHeroService } from './services/aw-hero.service';
import { AwLandingFunktionenService } from './services/aw-landing-funktionen.service';
import { AwLandingMobileService } from './services/aw-landing-mobile.service';
import { AwLandingWebService } from './services/aw-landing-web.service';
import { AwNotfallplaenePageService } from './services/aw-notfallplaene-page.service';
import { AwPartnerKurzService } from './services/aw-partner-kurz.service';
import { AwPartnerPageService } from './services/aw-partner-page.service';
import { AwPartnerService } from './services/aw-partner.service';
import { AwPremiumPartnerService } from './services/aw-premium-partner.service';
import { AwPricePageService } from './services/aw-price-page.service';
import { AwPriceService } from './services/aw-price.service';
import { AwPrueferBereicheService } from './services/aw-pruefer-bereiche.service';
import { AwPrueferPageService } from './services/aw-pruefer-page.service';
import { AwSlidesService } from './services/aw-slides.service';
import { BghJudgementTexteService } from './services/bgh-judgement-texte.service';
import { BghJudgementsService } from './services/bgh-judgements.service';
import { BlogPostCategoryService } from './services/blog-post-category.service';
import { BlogPostService } from './services/blog-post.service';
import { DatenschutzerklaerungService } from './services/datenschutzerklaerung.service';
import { FaqService } from './services/faq.service';
import { ImmoPartnerSectionService } from './services/immo-partner-section.service';
import { ImmoappGmbhService } from './services/immoapp-gmbh.service';
import { ImmoappPremiumPartnerService } from './services/immoapp-premium-partner.service';
import { ImmocheckDownloadPageService } from './services/immocheck-download-page.service';
import { ImmocheckDownloadsService } from './services/immocheck-downloads.service';
import { ImmocheckFaqService } from './services/immocheck-faq.service';
import { ImmocheckFeaturesService } from './services/immocheck-features.service';
import { ImmocheckFinePrintsService } from './services/immocheck-fine-prints.service';
import { ImmocheckLandingFeaturesService } from './services/immocheck-landing-features.service';
import { ImmocheckLandingMobileService } from './services/immocheck-landing-mobile.service';
import { ImmocheckLandingSlidesService } from './services/immocheck-landing-slides.service';
import { ImmocheckLandingWebService } from './services/immocheck-landing-web.service';
import { ImmocheckNutzeranleitungenService } from './services/immocheck-nutzeranleitungen.service';
import { ImmocheckOrderService } from './services/immocheck-order.service';
import { ImmocheckPartnerSectionService } from './services/immocheck-partner-section.service';
import { ImmocheckPremiumPartnerService } from './services/immocheck-premium-partner.service';
import { ImmocheckPriceFeaturesService } from './services/immocheck-price-features.service';
import { ImmocheckPriceService } from './services/immocheck-price.service';
import { ImmocheckReferralService } from './services/immocheck-referral.service';
import { ImmocheckSlidesService } from './services/immocheck-slides.service';
import { LandingImmoappAufzuwaerterService } from './services/landing-immoapp-aufzuwaerter.service';
import { LandingImmoappBeschlussAppService } from './services/landing-immoapp-beschluss-app.service';
import { LandingImmoappBghService } from './services/landing-immoapp-bgh.service';
import { LandingImmoappImmocheckService } from './services/landing-immoapp-immocheck.service';
import { NewsPageService } from './services/news-page.service';
import { PrueferService } from './services/pruefer.service';
import { UserManualsService } from './services/user-manuals.service';
import { EmailEmailService } from './services/email-email.service';
import { UploadFileService } from './services/upload-file.service';
import { UsersPermissionsRoleService } from './services/users-permissions-role.service';
import { UsersPermissionsUserService } from './services/users-permissions-user.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AgbService,
    AwBetriebssicherheitPageService,
    AwCallToActionService,
    AwContactService,
    AwDownloadPageService,
    AwDownloadService,
    AwFaqService,
    AwFunktionenService,
    AwHeroService,
    AwLandingFunktionenService,
    AwLandingMobileService,
    AwLandingWebService,
    AwNotfallplaenePageService,
    AwPartnerKurzService,
    AwPartnerPageService,
    AwPartnerService,
    AwPremiumPartnerService,
    AwPricePageService,
    AwPriceService,
    AwPrueferBereicheService,
    AwPrueferPageService,
    AwSlidesService,
    BghJudgementTexteService,
    BghJudgementsService,
    BlogPostCategoryService,
    BlogPostService,
    DatenschutzerklaerungService,
    FaqService,
    ImmoPartnerSectionService,
    ImmoappGmbhService,
    ImmoappPremiumPartnerService,
    ImmocheckDownloadPageService,
    ImmocheckDownloadsService,
    ImmocheckFaqService,
    ImmocheckFeaturesService,
    ImmocheckFinePrintsService,
    ImmocheckLandingFeaturesService,
    ImmocheckLandingMobileService,
    ImmocheckLandingSlidesService,
    ImmocheckLandingWebService,
    ImmocheckNutzeranleitungenService,
    ImmocheckOrderService,
    ImmocheckPartnerSectionService,
    ImmocheckPremiumPartnerService,
    ImmocheckPriceFeaturesService,
    ImmocheckPriceService,
    ImmocheckReferralService,
    ImmocheckSlidesService,
    LandingImmoappAufzuwaerterService,
    LandingImmoappBeschlussAppService,
    LandingImmoappBghService,
    LandingImmoappImmocheckService,
    NewsPageService,
    PrueferService,
    UserManualsService,
    EmailEmailService,
    UploadFileService,
    UsersPermissionsRoleService,
    UsersPermissionsUserService,
    ApiConfiguration
  ],
})
export class CMSApi {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<CMSApi> {
    return {
      ngModule: CMSApi,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: CMSApi,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('CMSApi is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
