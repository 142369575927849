import { tap } from 'rxjs/operators';
import {
  EditChecklist,
  UpdateChecklist,
  DeleteChecklist,
  ClearEditChecklist,
  AddLevel,
  DeleteLevel,
  UpdateLevel,
  DeleteKriterium,
  OpenLevel,
  CloseLevel,
  ToggleOpen,
  OpenAllLevels,
  CloseAllLevels,
  AddKriterium,
  UpdateKriterium,
  PublishAdminChecklist,
  UnpublishAdminChecklist,
} from './edit-checklist.action';
import {
  State,
  Action,
  StateContext,
  Store,
  Selector,
  createSelector,
} from '@ngxs/store';
import { Observable, zip } from 'rxjs';
import { patch, append, removeItem, updateItem } from '@ngxs/store/operators';
import { Injectable } from '@angular/core';
import {
  AddChecklistCriteriaV1Dto,
  AdminChecklistTemplateDetailsV1,
  ChecklistTemplateDetailsV1,
  ChecklistTemplateKriteriumV1,
  ChecklistTemplateLevelV1,
  Property,
  UpdateAdminChecklist,
  UpdateChecklistTemplateDto,
  UpdatePropertyChecklistDto,
} from '@api/models';
import { AuthState } from 'src/app/auth.state';
import {
  AdminService,
  ChecklistTemplatesService,
  PropertiesService,
} from '@api/services';
import { HotToastService } from '@ngneat/hot-toast';

export interface EditChecklistStateModel {
  checklist: ChecklistTemplateDetailsV1 | AdminChecklistTemplateDetailsV1;
  property: Property;
  levels: ChecklistTemplateLevelV1[];
  openLevels: number[];
}

@State<EditChecklistStateModel>({
  name: 'editChecklist',
  defaults: {
    checklist: null,
    property: null,
    levels: [],
    openLevels: [],
  },
})
@Injectable()
export class EditCheckListState {
  @Selector()
  static checklist(state: EditChecklistStateModel) {
    return state.checklist;
  }
  @Selector()
  static property(state: EditChecklistStateModel) {
    return state.property;
  }

  @Selector()
  static levels(state: EditChecklistStateModel) {
    return state.levels;
  }

  static levelOpen(level: number) {
    return createSelector(
      [EditCheckListState],
      (state: EditChecklistStateModel) => {
        return state.openLevels.some((o) => o === level);
      },
    );
  }

  constructor(
    private store: Store,
    private properties: PropertiesService,
    private authState: AuthState,
    private admin: AdminService,
    private checklistTemplate: ChecklistTemplatesService,
    private toast: HotToastService,
  ) {}

  @Action(EditChecklist)
  edit(ctx: StateContext<EditChecklistStateModel>, action: EditChecklist) {
    const isAdmin = this.authState.isAdmin;
    if (isAdmin) {
      return this.loadAdminChecklist(action.id).pipe(
        tap((checklist) =>
          ctx.patchState({
            checklist,
            levels: checklist.levels,
            openLevels: [],
            property: null,
          }),
        ),
      );
    }
    if (action.property) {
      return zip(
        this.loadPropertyChecklist(action.id, action.property),
        this.properties.findProperty({ propertyId: action.property }),
      ).pipe(
        tap(([checklist, property]) =>
          ctx.patchState({
            checklist,
            levels: checklist.levels,
            openLevels: [],
            property,
          }),
        ),
      );
    }
    return this.loadChecklistTemplate(action.id).pipe(
      tap((checklist) =>
        ctx.patchState({
          checklist,
          levels: checklist.levels,
          property: null,
          openLevels: [],
        }),
      ),
    );
  }

  loadChecklistTemplate(checklistId: number) {
    return this.checklistTemplate.checklistTemplateV1({
      checklistTemplateId: checklistId,
    });
  }

  loadAdminChecklist(checklistId: number) {
    return this.admin.adminChecklistV1({ checklistId });
  }

  loadPropertyChecklist(checklistId: number, propertyId: number) {
    return this.properties.propertyChecklistV1({
      propertyId,
      checklistId,
    });
  }

  @Action(UpdateChecklist)
  updateChecklist(
    ctx: StateContext<EditChecklistStateModel>,
    action: UpdateChecklist,
  ) {
    const isAdmin = this.authState.isAdmin;
    const property = ctx.getState().property;
    const checklist = ctx.getState().checklist;

    if (isAdmin && !property) {
      return this.updateAdminTemplate(action.payload, checklist).pipe(
        tap((c) => ctx.patchState({ checklist: c })),
      );
    } else if (!isAdmin && !property) {
      return this.updateChecklisteTemplate(action.payload, checklist).pipe(
        tap((c) => ctx.patchState({ checklist: c })),
      );
    } else if (!isAdmin && property) {
      return this.updateChecklistFromProperty(
        { name: action.payload.name, description: action.payload.description },
        property,
        checklist,
      ).pipe(tap((c) => ctx.patchState({ checklist: c })));
    }
  }

  updateChecklistFromProperty(
    payload: UpdatePropertyChecklistDto,
    property: Property,
    checklist: ChecklistTemplateDetailsV1,
  ) {
    return this.properties.updatePropertyChecklist({
      checklistId: checklist.id,
      propertyId: property.id,
      body: payload,
    });
  }

  updateChecklisteTemplate(
    payload: UpdateChecklistTemplateDto,
    checklist: ChecklistTemplateDetailsV1,
  ) {
    return this.checklistTemplate.updateChecklistTemplate({
      checklistTemplateId: checklist.id,
      body: payload,
    });
  }
  updateAdminTemplate(
    payload: UpdateAdminChecklist,
    checklist: ChecklistTemplateDetailsV1,
  ) {
    return this.admin.updateAdminChecklist({
      checklistId: checklist.id,
      body: payload,
    });
  }

  @Action(DeleteChecklist)
  deleteChecklist(ctx: StateContext<EditChecklistStateModel>) {
    const isAdmin = this.authState.isAdmin;
    const property = ctx.getState().property;
    const checklist = ctx.getState().checklist;
    if (isAdmin && !property) {
      return this.deleteAdminChecklistTemplate(checklist).pipe(
        tap(() => ctx.dispatch(new ClearEditChecklist())),
      );
    } else if (!isAdmin && !property) {
      return this.deleteChecklisteTemplate(checklist).pipe(
        tap(() => ctx.dispatch(new ClearEditChecklist())),
      );
    } else if (!isAdmin && property) {
      return this.deleteChecklistFromProperty(property, checklist).pipe(
        tap(() => ctx.dispatch(new ClearEditChecklist())),
      );
    }
  }

  @Action(ClearEditChecklist)
  clear(ctx: StateContext<EditChecklistStateModel>) {
    ctx.patchState({
      checklist: null,
      property: null,
      levels: [],
      openLevels: [],
    });
  }

  private deleteChecklistFromProperty(
    property: Property,
    checklist: ChecklistTemplateDetailsV1,
  ) {
    return this.properties.deleteChecklistFromProperty({
      propertyId: property.id,
      checklistId: checklist.id,
    });
  }

  private deleteChecklisteTemplate(checklist: ChecklistTemplateDetailsV1) {
    return this.checklistTemplate.deleteChecklistTemplate({
      checklistTemplateId: checklist.id,
    });
  }

  private deleteAdminChecklistTemplate(checklist: ChecklistTemplateDetailsV1) {
    return this.admin.deleteAdminChecklist({ checklistId: checklist.id });
  }

  @Action(AddLevel)
  addLevel(ctx: StateContext<EditChecklistStateModel>) {
    const payload = { name: 'Neuer Prüfbereich' };
    const property = ctx.getState().property;
    const checklist = ctx.getState().checklist;
    const isAdmin = this.authState.isAdmin;
    let ret: Observable<ChecklistTemplateDetailsV1>;
    if (isAdmin) {
      ret = this.admin.addAdminChecklistLevelV1({
        checklistId: checklist.id,
        body: payload,
      });
    } else if (property) {
      ret = this.properties.addPropertyChecklistLevelV1({
        checklistId: checklist.id,
        propertyId: property.id,
        body: payload,
      });
    } else {
      ret = this.checklistTemplate.addChecklistTemplateLevelV1({
        checklistTemplateId: checklist.id,
        body: payload,
      });
    }

    return ret.pipe(
      tap((c) => {
        const level = c.levels.reduce(function (prev, current) {
          if (+current.id > +prev.id) {
            return current;
          } else {
            return prev;
          }
        });
        ctx.patchState({ checklist: c });
        ctx.setState(patch({ levels: append([level]) }));
      }),
    );
  }
  @Action(DeleteLevel)
  deleteLevel(ctx: StateContext<EditChecklistStateModel>, action: DeleteLevel) {
    const property = ctx.getState().property;
    const checklist = ctx.getState().checklist;
    const isAdmin = this.authState.isAdmin;
    if (isAdmin) {
      return this.deleteLevelAdminTemplate(action.payload, checklist).pipe(
        tap((c) => {
          ctx.patchState({ checklist: c });
          ctx.setState(
            patch<EditChecklistStateModel>({
              levels: removeItem((l) => l.id === action.payload.id),
            }),
          );
        }),
      );
    } else if (property) {
      return this.deleteLevelPropertyChecklist(
        action.payload,
        checklist,
        property,
      ).pipe(
        tap((c) => {
          ctx.patchState({ checklist: c });
          ctx.setState(
            patch<EditChecklistStateModel>({
              levels: removeItem((l) => l.id === action.payload.id),
            }),
          );
        }),
      );
    } else {
      return this.deleteLevelChecklistTemplate(action.payload, checklist).pipe(
        tap((c) => {
          ctx.patchState({ checklist: c });
          ctx.setState(
            patch<EditChecklistStateModel>({
              levels: removeItem((l) => l.id === action.payload.id),
            }),
          );
        }),
      );
    }
  }

  private deleteLevelAdminTemplate(
    level: ChecklistTemplateLevelV1,
    checklist: ChecklistTemplateDetailsV1,
  ) {
    return this.admin.deleteAdminChecklistLevelV1({
      checklistId: checklist.id,
      levelId: level.id,
    });
  }
  private deleteLevelChecklistTemplate(
    level: ChecklistTemplateLevelV1,
    checklist: ChecklistTemplateDetailsV1,
  ) {
    return this.checklistTemplate.deleteChecklistTemplateLevelV1({
      checklistTemplateId: checklist.id,
      levelId: level.id,
    });
  }

  private deleteLevelPropertyChecklist(
    level: ChecklistTemplateLevelV1,
    checklist: ChecklistTemplateDetailsV1,
    property: Property,
  ) {
    return this.properties.deletePropertyChecklistLevelV1({
      checklistId: checklist.id,
      propertyId: property.id,
      levelId: level.id,
    });
  }

  @Action(UpdateLevel)
  updateLevel(ctx: StateContext<EditChecklistStateModel>, action: UpdateLevel) {
    let sub: Observable<ChecklistTemplateDetailsV1>;
    const property = ctx.getState().property;
    const checklist = ctx.getState().checklist;
    const isAdmin = this.authState.isAdmin;
    if (isAdmin && !property) {
      sub = this.admin.updateAdminChecklistLevelV1({
        checklistId: checklist.id,
        levelId: action.level.id,
        body: action.payload,
      });
    } else if (!isAdmin && !property) {
      sub = this.checklistTemplate.updateChecklistTemplateLevelV1({
        checklistTemplateId: checklist.id,
        levelId: action.level.id,
        body: action.payload,
      });
    } else if (!isAdmin && property) {
      sub = this.properties.updatePropertyChecklistLevelV1({
        checklistId: checklist.id,
        propertyId: property.id,
        levelId: action.level.id,
        body: action.payload,
      });
    }
    return sub.pipe(
      tap((c) => {
        const level = c.levels.find((l) => l.id === action.level.id);
        ctx.patchState({ checklist: c }),
          ctx.setState(
            patch({
              levels: updateItem<ChecklistTemplateLevelV1>(
                (l) => l.id === action.level.id,
                level,
              ),
            }),
          );
      }),
    );
  }

  @Action(AddKriterium)
  addKriterium(
    ctx: StateContext<EditChecklistStateModel>,
    action: AddKriterium,
  ) {
    const property = ctx.getState().property;
    const checklist = ctx.getState().checklist;
    const isAdmin = this.authState.isAdmin;
    let sub: Observable<ChecklistTemplateDetailsV1>;
    if (isAdmin) {
      sub = this.addKriteriumAdminTemplate(
        checklist,
        action.level,
        action.payload,
      );
    } else if (property) {
      sub = this.addKriteriumPropertyChecklist(
        checklist,
        action.level,
        property,
        action.payload,
      );
    } else {
      sub = this.addKriteriumChecklistTemplate(
        checklist,
        action.level,
        action.payload,
      );
    }

    return sub.pipe(
      tap((c) => {
        const level = c.levels.find((l) => l.id === action.level.id);
        ctx.patchState({ checklist: c }),
          ctx.setState(
            patch({
              levels: updateItem<ChecklistTemplateLevelV1>(
                (l) => l.id === action.level.id,
                level,
              ),
            }),
          );
      }),
    );
  }

  addKriteriumAdminTemplate(
    checklist: ChecklistTemplateDetailsV1,
    level: ChecklistTemplateLevelV1,
    payload: AddChecklistCriteriaV1Dto,
  ) {
    return this.admin.addAdminChecklistCriteriaV1({
      checklistId: checklist.id,
      levelId: level.id,
      body: payload,
    });
  }

  addKriteriumChecklistTemplate(
    checklist: ChecklistTemplateDetailsV1,
    level: ChecklistTemplateLevelV1,
    payload: AddChecklistCriteriaV1Dto,
  ) {
    return this.checklistTemplate.addChecklistTemplateCriteriaV1({
      checklistTemplateId: checklist.id,
      levelId: level.id,
      body: payload,
    });
  }

  addKriteriumPropertyChecklist(
    checklist: ChecklistTemplateDetailsV1,
    level: ChecklistTemplateLevelV1,
    property: Property,
    payload: AddChecklistCriteriaV1Dto,
  ) {
    return this.properties.addPropertyChecklistCriteriaV1({
      checklistId: checklist.id,
      propertyId: property.id,
      levelId: level.id,
      body: payload,
    });
  }

  @Action(UpdateKriterium)
  editKriterium(
    ctx: StateContext<EditChecklistStateModel>,
    action: UpdateKriterium,
  ) {
    const property = ctx.getState().property;
    const checklist = ctx.getState().checklist;
    const isAdmin = this.authState.isAdmin;
    let sub: Observable<ChecklistTemplateDetailsV1>;
    if (isAdmin) {
      sub = this.editKriteriumAdminTemplate(
        checklist,
        action.level,
        action.kriterium,
        action.payload,
      );
    } else if (property) {
      sub = this.editKriteriumPropertyChecklist(
        checklist,
        action.level,
        action.kriterium,
        property,
        action.payload,
      );
    } else {
      sub = this.editKriteriumChecklistTemplate(
        checklist,
        action.level,
        action.kriterium,
        action.payload,
      );
    }

    return sub.pipe(
      tap((c) => {
        const level = c.levels.find((l) => l.id === action.level.id);
        ctx.patchState({ checklist: c }),
          ctx.setState(
            patch({
              levels: updateItem<ChecklistTemplateLevelV1>(
                (l) => l.id === action.level.id,
                level,
              ),
            }),
          );
      }),
    );
  }

  editKriteriumAdminTemplate(
    checklist: ChecklistTemplateDetailsV1,
    level: ChecklistTemplateLevelV1,
    kriterium: ChecklistTemplateKriteriumV1,
    payload: AddChecklistCriteriaV1Dto,
  ) {
    return this.admin.updateAdminChecklistCriteriaV1({
      checklistId: checklist.id,
      levelId: level.id,
      criteriaId: kriterium.id,
      body: payload,
    });
  }

  editKriteriumChecklistTemplate(
    checklist: ChecklistTemplateDetailsV1,
    level: ChecklistTemplateLevelV1,
    kriterium: ChecklistTemplateKriteriumV1,
    payload: AddChecklistCriteriaV1Dto,
  ) {
    return this.checklistTemplate.updateChecklistTemplateCriteriaV1({
      checklistTemplateId: checklist.id,
      levelId: level.id,
      criteriaId: kriterium.id,
      body: payload,
    });
  }

  editKriteriumPropertyChecklist(
    checklist: ChecklistTemplateDetailsV1,
    level: ChecklistTemplateLevelV1,
    kriterium: ChecklistTemplateKriteriumV1,
    property: Property,
    payload: AddChecklistCriteriaV1Dto,
  ) {
    return this.properties.updateChecklistCriteriaV1({
      checklistId: checklist.id,
      propertyId: property.id,
      levelId: level.id,
      criteriaId: kriterium.id,
      body: payload,
    });
  }

  @Action(DeleteKriterium)
  deleteKriterium(
    ctx: StateContext<EditChecklistStateModel>,
    action: DeleteKriterium,
  ) {
    const property = ctx.getState().property;
    const checklist = ctx.getState().checklist;
    const isAdmin = this.authState.isAdmin;
    let ret: Observable<ChecklistTemplateDetailsV1>;
    if (isAdmin) {
      ret = this.admin.deleteAdminChecklistCriteriaV1({
        checklistId: checklist.id,
        levelId: action.level.id,
        criteriaId: action.kriterium.id,
      });
    } else if (property) {
      ret = this.properties.deleteChecklistCriteriaV1({
        checklistId: checklist.id,
        propertyId: property.id,
        levelId: action.level.id,
        criteriaId: action.kriterium.id,
      });
    } else {
      ret = this.checklistTemplate.deleteChecklistTemplateCriteriaV1({
        checklistTemplateId: checklist.id,
        levelId: action.level.id,
        criteriaId: action.kriterium.id,
      });
    }

    return ret.pipe(
      tap((c) => {
        const newLevel = c.levels.find((l) => l.id === action.level.id);
        newLevel.kriterien.push();
        ctx.patchState({ checklist: c });
        ctx.setState(
          patch({
            levels: updateItem<ChecklistTemplateLevelV1>(
              (l) => l.id === action.level.id,
              newLevel,
            ),
          }),
        );
      }),
    );
  }

  @Action(OpenLevel)
  openLevel(ctx: StateContext<EditChecklistStateModel>, action: OpenLevel) {
    const open = ctx.getState().openLevels;
    if (!open.some((o) => o === action.payload.id)) {
      ctx.setState(patch({ openLevels: append([action.payload.id]) }));
    }
  }

  @Action(CloseLevel)
  closeLevel(ctx: StateContext<EditChecklistStateModel>, action: CloseLevel) {
    const open = ctx.getState().openLevels;
    if (open.some((o) => o === action.payload.id)) {
      ctx.setState(
        patch({ openLevels: removeItem((o) => o === action.payload.id) }),
      );
    }
  }

  @Action(ToggleOpen)
  toggleLevel(ctx: StateContext<EditChecklistStateModel>, action: ToggleOpen) {
    const open = ctx.getState().openLevels;
    if (open.some((o) => o === action.payload.id)) {
      ctx.setState(
        patch({ openLevels: removeItem((o) => o === action.payload.id) }),
      );
    } else {
      ctx.setState(patch({ openLevels: append([action.payload.id]) }));
    }
  }

  @Action(OpenAllLevels)
  openAll(ctx: StateContext<EditChecklistStateModel>) {
    ctx.patchState({ openLevels: [...ctx.getState().levels.map((l) => l.id)] });
  }

  @Action(CloseAllLevels)
  closeAll(ctx: StateContext<EditChecklistStateModel>) {
    ctx.patchState({ openLevels: [] });
  }

  @Action(PublishAdminChecklist)
  publishAdminChecklist(
    ctx: StateContext<EditChecklistStateModel>,
    action: PublishAdminChecklist,
  ) {
    const checklist = ctx.getState().checklist;
    return this.admin.publishAdminChecklist({ checklistId: checklist.id }).pipe(
      this.toast.observe({
        loading: 'Veröffentliche Checklistenvorlage...',
        success: 'Checklistenvorlage veröffentlicht',
        error: 'Fehler beim Veröffentlichen der Checklistenvorlage',
      }),
      tap(() => {
        ctx.patchState({
          checklist: { ...checklist, publishedAt: new Date().toISOString() },
        });
      }),
    );
  }

  @Action(UnpublishAdminChecklist)
  unpublishAdminChecklist(
    ctx: StateContext<EditChecklistStateModel>,
    action: PublishAdminChecklist,
  ) {
    const checklist = ctx.getState().checklist;
    return this.admin
      .unpublishAdminChecklist({ checklistId: checklist.id })
      .pipe(
        tap(() => {
          ctx.patchState({ checklist: { ...checklist, publishedAt: null } });
        }),
      );
  }
}
