/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NewImmoappPremiumPartner } from '../models/new-immoapp-premium-partner';

@Injectable({
  providedIn: 'root',
})
export class ImmoappPremiumPartnerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation immoappPremiumPartnersGet
   */
  static readonly ImmoappPremiumPartnersGetPath = '/immoapp-premium-partners';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `immoappPremiumPartnersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  immoappPremiumPartnersGet$Response(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ImmoappPremiumPartnerService.ImmoappPremiumPartnersGetPath, 'get');
    if (params) {
      rb.query('_limit', params['_limit'], {});
      rb.query('_sort', params['_sort'], {});
      rb.query('_start', params['_start'], {});
      rb.query('=', params['='], {});
      rb.query('_ne', params['_ne'], {});
      rb.query('_lt', params['_lt'], {});
      rb.query('_lte', params['_lte'], {});
      rb.query('_gt', params['_gt'], {});
      rb.query('_gte', params['_gte'], {});
      rb.query('_contains', params['_contains'], {});
      rb.query('_containss', params['_containss'], {});
      rb.query('_in', params['_in'], {});
      rb.query('_nin', params['_nin'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `immoappPremiumPartnersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  immoappPremiumPartnersGet(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<{
'foo'?: string;
}> {

    return this.immoappPremiumPartnersGet$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation immoappPremiumPartnersPost
   */
  static readonly ImmoappPremiumPartnersPostPath = '/immoapp-premium-partners';

  /**
   * Create a new record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `immoappPremiumPartnersPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  immoappPremiumPartnersPost$Response(params: {
    body: NewImmoappPremiumPartner
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ImmoappPremiumPartnerService.ImmoappPremiumPartnersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Create a new record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `immoappPremiumPartnersPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  immoappPremiumPartnersPost(params: {
    body: NewImmoappPremiumPartner
  }): Observable<{
'foo'?: string;
}> {

    return this.immoappPremiumPartnersPost$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation immoappPremiumPartnersCountGet
   */
  static readonly ImmoappPremiumPartnersCountGetPath = '/immoapp-premium-partners/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `immoappPremiumPartnersCountGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  immoappPremiumPartnersCountGet$Response(params?: {
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ImmoappPremiumPartnerService.ImmoappPremiumPartnersCountGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `immoappPremiumPartnersCountGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  immoappPremiumPartnersCountGet(params?: {
  }): Observable<{
'foo'?: string;
}> {

    return this.immoappPremiumPartnersCountGet$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation immoappPremiumPartnersIdGet
   */
  static readonly ImmoappPremiumPartnersIdGetPath = '/immoapp-premium-partners/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `immoappPremiumPartnersIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  immoappPremiumPartnersIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ImmoappPremiumPartnerService.ImmoappPremiumPartnersIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `immoappPremiumPartnersIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  immoappPremiumPartnersIdGet(params: {
    id: string;
  }): Observable<{
'foo'?: string;
}> {

    return this.immoappPremiumPartnersIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation immoappPremiumPartnersIdPut
   */
  static readonly ImmoappPremiumPartnersIdPutPath = '/immoapp-premium-partners/{id}';

  /**
   * Update a record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `immoappPremiumPartnersIdPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  immoappPremiumPartnersIdPut$Response(params: {
    id: string;
    body: NewImmoappPremiumPartner
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ImmoappPremiumPartnerService.ImmoappPremiumPartnersIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Update a record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `immoappPremiumPartnersIdPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  immoappPremiumPartnersIdPut(params: {
    id: string;
    body: NewImmoappPremiumPartner
  }): Observable<{
'foo'?: string;
}> {

    return this.immoappPremiumPartnersIdPut$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation immoappPremiumPartnersIdDelete
   */
  static readonly ImmoappPremiumPartnersIdDeletePath = '/immoapp-premium-partners/{id}';

  /**
   * Delete a record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `immoappPremiumPartnersIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  immoappPremiumPartnersIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ImmoappPremiumPartnerService.ImmoappPremiumPartnersIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Delete a record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `immoappPremiumPartnersIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  immoappPremiumPartnersIdDelete(params: {
    id: string;
  }): Observable<number> {

    return this.immoappPremiumPartnersIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
