import { Routes } from '@angular/router';
import { RedirectLoggedIn } from '@guards/redirect-logged-in.guard';
import { AuthGuard } from '@guards/auth.guard';
import { portalAccessRoles } from './auth-roles';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/auth/login/login.module').then(
            (m) => m.LoginPageModule,
          ),
        canActivate: [RedirectLoggedIn()],
      },
      {
        path: 'password/request-reset',
        loadChildren: () =>
          import(
            './pages/auth/password/password-request-reset/password-request-reset.module'
          ).then((m) => m.PasswordRequestResetPageModule),
      },
      {
        path: 'password/reset',
        loadChildren: () =>
          import(
            './pages/auth/password/password-reset/password-reset.module'
          ).then((m) => m.PasswordResetPageModule),
      },
      {
        path: '',
        loadChildren: () =>
          import('./unauthorized.component').then((m) => m.UnauthorizedModule),
      },
      {
        path: '',
        loadChildren: () =>
          import('./authorized.component').then((m) => m.AuthorizedModule),
        canActivate: [AuthGuard(...portalAccessRoles)],
      },
      {
        path: '**',
        loadComponent: () =>
          import('./pages/page-not-found/page-not-found.page').then(
            (m) => m.PageNotFoundPage,
          ),
      },
    ],
  },
];
