import { UserRole } from '@api/models';

export const portalAndAdminRoles: UserRole[] = [
  UserRole.Admin,
  UserRole.Manager,
  UserRole.Employee,
];
export const portalRoles: UserRole[] = [UserRole.Manager, UserRole.Employee];
export const appRoles: UserRole[] = [
  UserRole.Craftsman,
  UserRole.HouseCleaner,
  UserRole.Owner,
  UserRole.Tenant,
  UserRole.User,
];

export const portalAccessRoles: UserRole[] = [
  ...portalAndAdminRoles,
  ...appRoles,
];
