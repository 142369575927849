import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root',
})
export class PaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();

    this.init();
  }

  init() {
    this.itemsPerPageLabel = 'Ergebnisse pro Seite';
    this.firstPageLabel = 'Erste Seite';
    this.nextPageLabel = 'Nächste Seite';
    this.previousPageLabel = 'Vorherige Seite';
    this.lastPageLabel = 'Letzte Seite';
  }

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    const rangeof = 'von';

    if (length === 0 || pageSize === 0) {
      return `0 ${rangeof} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${rangeof} ${length}`;
  };
}
