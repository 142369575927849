import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

import * as Sentry from '@sentry/angular';

if (environment.production) {
  Sentry.init({
    dsn: environment.sentry.url,
    environment: environment.sentry.environment,
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: [
      'localhost',
      environment.nestApi,
      environment.baseURL,
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  });
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
