/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NewImmocheckDownloads } from '../models/new-immocheck-downloads';

@Injectable({
  providedIn: 'root',
})
export class ImmocheckDownloadsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation immocheckDownloadsGet
   */
  static readonly ImmocheckDownloadsGetPath = '/immocheck-downloads';

  /**
   * Find all the immocheck-downloads's records
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `immocheckDownloadsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  immocheckDownloadsGet$Response(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ImmocheckDownloadsService.ImmocheckDownloadsGetPath, 'get');
    if (params) {
      rb.query('_limit', params['_limit'], {});
      rb.query('_sort', params['_sort'], {});
      rb.query('_start', params['_start'], {});
      rb.query('=', params['='], {});
      rb.query('_ne', params['_ne'], {});
      rb.query('_lt', params['_lt'], {});
      rb.query('_lte', params['_lte'], {});
      rb.query('_gt', params['_gt'], {});
      rb.query('_gte', params['_gte'], {});
      rb.query('_contains', params['_contains'], {});
      rb.query('_containss', params['_containss'], {});
      rb.query('_in', params['_in'], {});
      rb.query('_nin', params['_nin'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Find all the immocheck-downloads's records
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `immocheckDownloadsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  immocheckDownloadsGet(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<{
'foo'?: string;
}> {

    return this.immocheckDownloadsGet$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation immocheckDownloadsPost
   */
  static readonly ImmocheckDownloadsPostPath = '/immocheck-downloads';

  /**
   * Create a new immocheck-downloads record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `immocheckDownloadsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  immocheckDownloadsPost$Response(params: {
    body: NewImmocheckDownloads
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ImmocheckDownloadsService.ImmocheckDownloadsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Create a new immocheck-downloads record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `immocheckDownloadsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  immocheckDownloadsPost(params: {
    body: NewImmocheckDownloads
  }): Observable<{
'foo'?: string;
}> {

    return this.immocheckDownloadsPost$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation immocheckDownloadsCountGet
   */
  static readonly ImmocheckDownloadsCountGetPath = '/immocheck-downloads/count';

  /**
   * Retrieve the number of immocheck-downloads documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `immocheckDownloadsCountGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  immocheckDownloadsCountGet$Response(params?: {
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ImmocheckDownloadsService.ImmocheckDownloadsCountGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Retrieve the number of immocheck-downloads documents
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `immocheckDownloadsCountGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  immocheckDownloadsCountGet(params?: {
  }): Observable<{
'foo'?: string;
}> {

    return this.immocheckDownloadsCountGet$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation immocheckDownloadsIdGet
   */
  static readonly ImmocheckDownloadsIdGetPath = '/immocheck-downloads/{id}';

  /**
   * Find one immocheck-downloads record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `immocheckDownloadsIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  immocheckDownloadsIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ImmocheckDownloadsService.ImmocheckDownloadsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Find one immocheck-downloads record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `immocheckDownloadsIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  immocheckDownloadsIdGet(params: {
    id: string;
  }): Observable<{
'foo'?: string;
}> {

    return this.immocheckDownloadsIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation immocheckDownloadsIdPut
   */
  static readonly ImmocheckDownloadsIdPutPath = '/immocheck-downloads/{id}';

  /**
   * Update a single immocheck-downloads record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `immocheckDownloadsIdPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  immocheckDownloadsIdPut$Response(params: {
    id: string;
    body: NewImmocheckDownloads
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ImmocheckDownloadsService.ImmocheckDownloadsIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Update a single immocheck-downloads record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `immocheckDownloadsIdPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  immocheckDownloadsIdPut(params: {
    id: string;
    body: NewImmocheckDownloads
  }): Observable<{
'foo'?: string;
}> {

    return this.immocheckDownloadsIdPut$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation immocheckDownloadsIdDelete
   */
  static readonly ImmocheckDownloadsIdDeletePath = '/immocheck-downloads/{id}';

  /**
   * Delete a single immocheck-downloads record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `immocheckDownloadsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  immocheckDownloadsIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ImmocheckDownloadsService.ImmocheckDownloadsIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Delete a single immocheck-downloads record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `immocheckDownloadsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  immocheckDownloadsIdDelete(params: {
    id: string;
  }): Observable<number> {

    return this.immocheckDownloadsIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
