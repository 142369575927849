import { DomSanitizer } from '@angular/platform-browser';
import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-form-wrapper',
  template: `
    <div
      class="grid gap-4"
      [ngClass]="{
        'md:grid-cols-3 md:gap-8': !props.small,
      }"
    >
      <div class="col-span-1">
        <h3>{{ to.label }}</h3>
        <p
          *ngIf="to.description && !to.embedDescription"
          class="mt-2 text-sm text-gray-600"
        >
          {{ to.description }}
        </p>
        <p
          *ngIf="to.description && to.embedDescription"
          class="mt-2 text-sm text-gray-600"
          [innerHtml]="sanitizer.bypassSecurityTrustHtml(to.description)"
        ></p>
        <p *ngIf="to.hint" class="mt-2 text-xs text-yellow-600">
          {{ to.hint }}
        </p>
      </div>
      <div class="col-span-2">
        <ng-container #fieldComponent></ng-container>
      </div>
    </div>
  `,
})
export class FormWrapperComponent extends FieldWrapper {
  defaultOptions = { props: { embedDescription: false } };

  constructor(public sanitizer: DomSanitizer) {
    super();
  }
}
