import {
  AddChecklistCriteriaV1Dto,
  AddChecklistLevelV1Dto,
  ChecklistTemplateKriteriumV1,
  ChecklistTemplateLevelV1,
  UpdateChecklistTemplateDto,
} from '@api/models';

export class EditChecklist {
  static readonly type = '[EditChecklist] EditChecklist]';
  constructor(
    public readonly id: number,
    public readonly property?: number,
  ) {}
}

export class UpdateChecklist {
  static readonly type = '[EditChecklist] UpdateChecklist]';
  constructor(public readonly payload: UpdateChecklistTemplateDto) {}
}
export class DeleteChecklist {
  static readonly type = '[EditChecklist] DeleteChecklist]';
}
export class ClearEditChecklist {
  static readonly type = '[EditChecklist] ClearEditChecklist]';
}
export class AddLevel {
  static readonly type = '[EditChecklist] AddLevel]';
}
export class DeleteLevel {
  static readonly type = '[EditChecklist] DeleteLevel]';
  constructor(public readonly payload: ChecklistTemplateLevelV1) {}
}
export class OpenLevel {
  static readonly type = '[EditChecklist] OpenLevel]';
  constructor(public readonly payload: ChecklistTemplateLevelV1) {}
}
export class ToggleOpen {
  static readonly type = '[EditChecklist] ToggleOpen]';
  constructor(public readonly payload: ChecklistTemplateLevelV1) {}
}
export class CloseLevel {
  static readonly type = '[EditChecklist] CloseLevel]';
  constructor(public readonly payload: ChecklistTemplateLevelV1) {}
}
export class CloseAllLevels {
  static readonly type = '[EditChecklist] CloseAllLevels]';
}
export class OpenAllLevels {
  static readonly type = '[EditChecklist] OpenAllLevels]';
}
export class UpdateLevel {
  static readonly type = '[EditChecklist] UpdateLevel]';
  constructor(
    public readonly level: ChecklistTemplateLevelV1,
    public readonly payload: AddChecklistLevelV1Dto,
  ) {}
}
export class AddKriterium {
  static readonly type = '[EditChecklist] AddKriterium]';
  constructor(
    public readonly level: ChecklistTemplateLevelV1,
    public readonly payload: AddChecklistCriteriaV1Dto,
  ) {}
}
export class DeleteKriterium {
  static readonly type = '[EditChecklist] DeleteKriterium]';
  constructor(
    public readonly level: ChecklistTemplateLevelV1,
    public readonly kriterium: ChecklistTemplateKriteriumV1,
  ) {}
}
export class UpdateKriterium {
  static readonly type = '[EditChecklist] UpdateKriterium]';
  constructor(
    public readonly kriterium: ChecklistTemplateKriteriumV1,
    public readonly level: ChecklistTemplateLevelV1,
    public readonly payload: AddChecklistCriteriaV1Dto,
  ) {}
}

export class PublishAdminChecklist {
  static readonly type = '[EditChecklist] publishAdminChecklist]';
  constructor() {}
}

export class UnpublishAdminChecklist {
  static readonly type = '[EditChecklist] UnpublishAdminChecklist]';
  constructor() {}
}
