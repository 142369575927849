/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Inspection } from '../models/inspection';
import { InspectionInfo } from '../models/inspection-info';
import { OpenInspection } from '../models/open-inspection';
import { Page } from '../models/page';
import { SortOrder } from '../models/sort-order';

@Injectable({ providedIn: 'root' })
export class InspectionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pageInspections()` */
  static readonly PageInspectionsPath = '/inspections';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageInspections()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageInspections$Response(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<InspectionInfo>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, InspectionsService.PageInspectionsPath, 'get');
    if (params) {
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<InspectionInfo>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageInspections$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageInspections(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<InspectionInfo>;
}> {
    return this.pageInspections$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<InspectionInfo>;
}>): Page & {
'result': Array<InspectionInfo>;
} => r.body)
    );
  }

  /** Path part for operation `findOpenInspection()` */
  static readonly FindOpenInspectionPath = '/inspections/open/{inspectionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOpenInspection()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOpenInspection$Response(
    params: {
      inspectionId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OpenInspection>> {
    const rb = new RequestBuilder(this.rootUrl, InspectionsService.FindOpenInspectionPath, 'get');
    if (params) {
      rb.path('inspectionId', params.inspectionId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OpenInspection>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOpenInspection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOpenInspection(
    params: {
      inspectionId: number;
    },
    context?: HttpContext
  ): Observable<OpenInspection> {
    return this.findOpenInspection$Response(params, context).pipe(
      map((r: StrictHttpResponse<OpenInspection>): OpenInspection => r.body)
    );
  }

  /** Path part for operation `inspection()` */
  static readonly InspectionPath = '/inspections/{inspectionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inspection()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  inspection$Response(
    params: {
      inspectionId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Inspection>> {
    const rb = new RequestBuilder(this.rootUrl, InspectionsService.InspectionPath, 'get');
    if (params) {
      rb.path('inspectionId', params.inspectionId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Inspection>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inspection$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  inspection(
    params: {
      inspectionId: number;
    },
    context?: HttpContext
  ): Observable<Inspection> {
    return this.inspection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Inspection>): Inspection => r.body)
    );
  }

}
