import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthState } from '../auth.state';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  constructor(private authState: AuthState) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.cms)) {
      return next.handle(req);
    }

    const token = this.authState.snapshot?.accessToken;
    if (token !== undefined) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(req);
  }
}
