/* tslint:disable */
/* eslint-disable */
export enum UserRole {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Employee = 'EMPLOYEE',
  User = 'USER',
  Owner = 'OWNER',
  Tenant = 'TENANT',
  Craftsman = 'CRAFTSMAN',
  HouseCleaner = 'HOUSE_CLEANER'
}
