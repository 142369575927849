import { InjectionToken, Injectable, Inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpContextToken,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

export const IS_TIMEOUT_ENABLED = new HttpContextToken<boolean>(() => true);

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  static TIMEOUT = 'timeout';

  constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!req.context.get(IS_TIMEOUT_ENABLED)) {
      return next.handle(req);
    }

    const timeoutValue =
      req.headers.get(TimeoutInterceptor.TIMEOUT) || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);

    return next.handle(req).pipe(timeout(timeoutValueNumeric));
  }
}
