/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Faq } from '../models/faq';
import { NewFaq } from '../models/new-faq';

@Injectable({
  providedIn: 'root',
})
export class FaqService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation faqsGet
   */
  static readonly FaqsGetPath = '/faqs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqsGet$Response(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<StrictHttpResponse<Array<Faq>>> {

    const rb = new RequestBuilder(this.rootUrl, FaqService.FaqsGetPath, 'get');
    if (params) {
      rb.query('_limit', params['_limit'], {});
      rb.query('_sort', params['_sort'], {});
      rb.query('_start', params['_start'], {});
      rb.query('=', params['='], {});
      rb.query('_ne', params['_ne'], {});
      rb.query('_lt', params['_lt'], {});
      rb.query('_lte', params['_lte'], {});
      rb.query('_gt', params['_gt'], {});
      rb.query('_gte', params['_gte'], {});
      rb.query('_contains', params['_contains'], {});
      rb.query('_containss', params['_containss'], {});
      rb.query('_in', params['_in'], {});
      rb.query('_nin', params['_nin'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Faq>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `faqsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqsGet(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<Array<Faq>> {

    return this.faqsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Faq>>) => r.body as Array<Faq>)
    );
  }

  /**
   * Path part for operation faqsPost
   */
  static readonly FaqsPostPath = '/faqs';

  /**
   * Create a new record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faqsPost$Response(params: {
    body: NewFaq
  }): Observable<StrictHttpResponse<Faq>> {

    const rb = new RequestBuilder(this.rootUrl, FaqService.FaqsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Faq>;
      })
    );
  }

  /**
   * Create a new record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `faqsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faqsPost(params: {
    body: NewFaq
  }): Observable<Faq> {

    return this.faqsPost$Response(params).pipe(
      map((r: StrictHttpResponse<Faq>) => r.body as Faq)
    );
  }

  /**
   * Path part for operation faqsCountGet
   */
  static readonly FaqsCountGetPath = '/faqs/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqsCountGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqsCountGet$Response(params?: {
  }): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, FaqService.FaqsCountGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `faqsCountGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqsCountGet(params?: {
  }): Observable<{
'count'?: number;
}> {

    return this.faqsCountGet$Response(params).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation faqsIdGet
   */
  static readonly FaqsIdGetPath = '/faqs/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqsIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqsIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Faq>> {

    const rb = new RequestBuilder(this.rootUrl, FaqService.FaqsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Faq>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `faqsIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqsIdGet(params: {
    id: string;
  }): Observable<Faq> {

    return this.faqsIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<Faq>) => r.body as Faq)
    );
  }

  /**
   * Path part for operation faqsIdPut
   */
  static readonly FaqsIdPutPath = '/faqs/{id}';

  /**
   * Update a record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqsIdPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faqsIdPut$Response(params: {
    id: string;
    body: NewFaq
  }): Observable<StrictHttpResponse<Faq>> {

    const rb = new RequestBuilder(this.rootUrl, FaqService.FaqsIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Faq>;
      })
    );
  }

  /**
   * Update a record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `faqsIdPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faqsIdPut(params: {
    id: string;
    body: NewFaq
  }): Observable<Faq> {

    return this.faqsIdPut$Response(params).pipe(
      map((r: StrictHttpResponse<Faq>) => r.body as Faq)
    );
  }

  /**
   * Path part for operation faqsIdDelete
   */
  static readonly FaqsIdDeletePath = '/faqs/{id}';

  /**
   * Delete a record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqsIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, FaqService.FaqsIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Delete a record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `faqsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqsIdDelete(params: {
    id: string;
  }): Observable<number> {

    return this.faqsIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
