export const environment = {
  production: true,
  baseURL: 'https://immoapp.de/backend/immoapps/api',
  nestApi: 'https://api.immocheckapp.de',
  cms: 'https://cms.immoapp.de',
  url: 'https://immocheckapp.de/',
  title: 'Die immocheck-app',
  description:
    'Organisieren Sie die Arbeitskontrolle der Handwerker und Hausmeister mit Hilfe der immocheck-app. Ganz einfach ausprobieren im kostenlosen Probemonat!',
  featureImage: 'assets/banner.png',
  sentry: {
    url: 'https://a298eb83ec2243b992de4fe6742604b5@o4505487655239680.ingest.sentry.io/4505487679881216',
    environment: 'production',
  },
  keywords: [
    'immocheck-app',
    'Arbeitskontrolle',
    'Protokolle',
    'Hausmeister',
    'Checkliste',
    'Hausverwaltung',
    'Digitalisierung',
  ],
};
