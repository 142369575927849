/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NewAwPrueferPage } from '../models/new-aw-pruefer-page';

@Injectable({
  providedIn: 'root',
})
export class AwPrueferPageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation awPrueferPageGet
   */
  static readonly AwPrueferPageGetPath = '/aw-pruefer-page';

  /**
   * Find all the aw-pruefer-page's records
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awPrueferPageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  awPrueferPageGet$Response(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwPrueferPageService.AwPrueferPageGetPath, 'get');
    if (params) {
      rb.query('_limit', params['_limit'], {});
      rb.query('_sort', params['_sort'], {});
      rb.query('_start', params['_start'], {});
      rb.query('=', params['='], {});
      rb.query('_ne', params['_ne'], {});
      rb.query('_lt', params['_lt'], {});
      rb.query('_lte', params['_lte'], {});
      rb.query('_gt', params['_gt'], {});
      rb.query('_gte', params['_gte'], {});
      rb.query('_contains', params['_contains'], {});
      rb.query('_containss', params['_containss'], {});
      rb.query('_in', params['_in'], {});
      rb.query('_nin', params['_nin'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Find all the aw-pruefer-page's records
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `awPrueferPageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  awPrueferPageGet(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<{
'foo'?: string;
}> {

    return this.awPrueferPageGet$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation awPrueferPagePut
   */
  static readonly AwPrueferPagePutPath = '/aw-pruefer-page';

  /**
   * Update a single aw-pruefer-page record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awPrueferPagePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awPrueferPagePut$Response(params: {
    body: NewAwPrueferPage
  }): Observable<StrictHttpResponse<{
'foo'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwPrueferPageService.AwPrueferPagePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'foo'?: string;
        }>;
      })
    );
  }

  /**
   * Update a single aw-pruefer-page record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `awPrueferPagePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awPrueferPagePut(params: {
    body: NewAwPrueferPage
  }): Observable<{
'foo'?: string;
}> {

    return this.awPrueferPagePut$Response(params).pipe(
      map((r: StrictHttpResponse<{
'foo'?: string;
}>) => r.body as {
'foo'?: string;
})
    );
  }

  /**
   * Path part for operation awPrueferPageDelete
   */
  static readonly AwPrueferPageDeletePath = '/aw-pruefer-page';

  /**
   * Delete a single aw-pruefer-page record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awPrueferPageDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  awPrueferPageDelete$Response(params?: {
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, AwPrueferPageService.AwPrueferPageDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Delete a single aw-pruefer-page record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `awPrueferPageDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  awPrueferPageDelete(params?: {
  }): Observable<number> {

    return this.awPrueferPageDelete$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
