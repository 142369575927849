/* tslint:disable */
/* eslint-disable */
export enum TaskScheduleOption {
  Daily = 'DAILY',
  EveryWeekday = 'EVERY_WEEKDAY',
  OneTime = 'ONE_TIME',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}
