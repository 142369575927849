/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Agb } from '../models/agb';
import { NewAgb } from '../models/new-agb';

@Injectable({
  providedIn: 'root',
})
export class AgbService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation agbGet
   */
  static readonly AgbGetPath = '/agb';

  /**
   * Find all the agb's records
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agbGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  agbGet$Response(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<StrictHttpResponse<Array<Agb>>> {

    const rb = new RequestBuilder(this.rootUrl, AgbService.AgbGetPath, 'get');
    if (params) {
      rb.query('_limit', params['_limit'], {});
      rb.query('_sort', params['_sort'], {});
      rb.query('_start', params['_start'], {});
      rb.query('=', params['='], {});
      rb.query('_ne', params['_ne'], {});
      rb.query('_lt', params['_lt'], {});
      rb.query('_lte', params['_lte'], {});
      rb.query('_gt', params['_gt'], {});
      rb.query('_gte', params['_gte'], {});
      rb.query('_contains', params['_contains'], {});
      rb.query('_containss', params['_containss'], {});
      rb.query('_in', params['_in'], {});
      rb.query('_nin', params['_nin'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Agb>>;
      })
    );
  }

  /**
   * Find all the agb's records
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `agbGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agbGet(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<Array<Agb>> {

    return this.agbGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Agb>>) => r.body as Array<Agb>)
    );
  }

  /**
   * Path part for operation agbPut
   */
  static readonly AgbPutPath = '/agb';

  /**
   * Update a single agb record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agbPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agbPut$Response(params: {
    body: NewAgb
  }): Observable<StrictHttpResponse<Agb>> {

    const rb = new RequestBuilder(this.rootUrl, AgbService.AgbPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Agb>;
      })
    );
  }

  /**
   * Update a single agb record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `agbPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agbPut(params: {
    body: NewAgb
  }): Observable<Agb> {

    return this.agbPut$Response(params).pipe(
      map((r: StrictHttpResponse<Agb>) => r.body as Agb)
    );
  }

  /**
   * Path part for operation agbDelete
   */
  static readonly AgbDeletePath = '/agb';

  /**
   * Delete a single agb record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agbDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  agbDelete$Response(params?: {
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, AgbService.AgbDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Delete a single agb record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `agbDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agbDelete(params?: {
  }): Observable<number> {

    return this.agbDelete$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
