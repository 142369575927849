/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { InspectionImageContent } from '../models/inspection-image-content';

@Injectable({ providedIn: 'root' })
export class ImagesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendInspectionImage()` */
  static readonly SendInspectionImagePath = '/images/inspection/{imageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendInspectionImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendInspectionImage$Response(
    params: {
      imageId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ImagesService.SendInspectionImagePath, 'get');
    if (params) {
      rb.path('imageId', params.imageId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendInspectionImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendInspectionImage(
    params: {
      imageId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sendInspectionImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sendInspectionImageContent()` */
  static readonly SendInspectionImageContentPath = '/images/inspection/{imageId}/base64';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendInspectionImageContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendInspectionImageContent$Response(
    params: {
      imageId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InspectionImageContent>> {
    const rb = new RequestBuilder(this.rootUrl, ImagesService.SendInspectionImageContentPath, 'get');
    if (params) {
      rb.path('imageId', params.imageId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InspectionImageContent>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendInspectionImageContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendInspectionImageContent(
    params: {
      imageId: number;
    },
    context?: HttpContext
  ): Observable<InspectionImageContent> {
    return this.sendInspectionImageContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<InspectionImageContent>): InspectionImageContent => r.body)
    );
  }

}
