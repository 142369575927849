import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { Auth, UserRole } from '@api/models';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { required } from '@notiz/toolbelt/rxjs';
import { isPlatformBrowser } from '@angular/common';
import { appRoles } from './auth-roles';

@Injectable({
  providedIn: 'root',
})
export class AuthState {
  private static AUTH_KEY = 'auth';
  _auth$ = new BehaviorSubject<Auth | null | undefined>(undefined);

  private _loggedOut$ = new Subject<void>();

  loggedOut$ = this._loggedOut$.asObservable();

  isLoggedIn$ = this._auth$
    .asObservable()
    .pipe(map((auth) => !!auth?.accessToken && !!auth?.user));

  accessToken$ = this._auth$
    .asObservable()
    .pipe(map((auth) => auth?.accessToken));

  refreshToken$ = this._auth$
    .asObservable()
    .pipe(map((auth) => auth?.refreshToken));

  user$ = this._auth$.asObservable().pipe(map((auth) => auth?.user));

  hasRole$ = (...roles: UserRole[]): Observable<boolean> =>
    this.user$.pipe(
      required(),
      map((user) => roles.includes(user.userRole)),
    );

  isAdmin$ = this.hasRole$(UserRole.Admin);

  get snapshot() {
    return this._auth$.getValue();
  }

  get user() {
    return this.snapshot?.user;
  }

  hasRole = (...roles: UserRole[]): boolean =>
    roles.includes(this.user?.userRole);

  get isAdmin(): boolean {
    return this.hasRole(UserRole.Admin);
  }

  constructor(
    private router: Router,
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.loadState();
    } else {
      this._auth$.next(null);
    }
  }

  login(auth: Auth, returnUrl?: string) {
    this._auth$.next(auth);
    this.persistState(auth);

    let navigateByUrl = returnUrl || '/portal';

    if (appRoles.includes(auth.user.userRole)) {
      navigateByUrl = '/settings';
    }

    this.zone.run(() =>
      this.router.navigateByUrl(navigateByUrl, { replaceUrl: true }),
    );
  }

  logout() {
    this._auth$.next(null);
    this._loggedOut$.next();
    this.clearState();
    this.zone.run(() =>
      // this.router.navigateByUrl('/login', { replaceUrl: true }),
      {
        window.location.href = '/login';
      },
    );
  }

  private loadState() {
    const authState = localStorage.getItem(AuthState.AUTH_KEY);

    if (authState) {
      this._auth$.next(JSON.parse(authState));
    } else {
      this._auth$.next(null);
    }
  }

  private persistState(auth: Auth) {
    localStorage.setItem(AuthState.AUTH_KEY, JSON.stringify(auth));
  }

  private clearState() {
    localStorage.removeItem(AuthState.AUTH_KEY);
  }
}
