import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map, take } from 'rxjs';
import { AuthState } from '../auth.state';

export const RedirectLoggedIn: () => CanActivateFn = () => (route, state) => {
  const router = inject(Router);
  const authState = inject(AuthState);

  const returnUrl = route.queryParamMap.get('returnUrl');

  return authState.user$.pipe(
    take(1),
    map((user) => !!user),
    map((loggedIn) => {
      if (loggedIn) {
        return router.parseUrl(returnUrl || '/portal');
      } else {
        return true;
      }
    }),
  );
};
