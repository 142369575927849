import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserRole } from '@api/models';
import { map, take } from 'rxjs';
import { AuthState } from '../auth.state';

export const AuthGuard: (...roles: UserRole[]) => CanActivateFn =
  (...roles: UserRole[]) =>
  (route, state) => {
    const router = inject(Router);
    const authState = inject(AuthState);

    return authState.user$.pipe(
      take(1),
      map((user) => {
        const loggedIn = !!user;
        const hasRoles = (...roles: UserRole[]): boolean =>
          roles.some((role) => role === user.userRole);

        if (!loggedIn) {
          return router.parseUrl(`/login?returnUrl=${state.url}`);
        } else if (roles.length > 0 && !hasRoles(...roles)) {
          return router.parseUrl(`/forbidden?url=${state.url}`);
        }

        return true;
      }),
    );
  };
