import {
  APP_INITIALIZER,
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  importProvidersFrom,
} from '@angular/core';
import {
  Router,
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
} from '@angular/router';

import { routes } from './app.routes';
import {
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { environment } from '@environments/environment';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { JWTInterceptor } from '@interceptors/jwt.interceptor';
import { ResponseInterceptor } from '@interceptors/response.interceptor';
import {
  DEFAULT_TIMEOUT,
  TimeoutInterceptor,
} from '@interceptors/timeout.interceptor';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { PaginatorIntl } from '@utils/paginator-intl';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideMarkdown } from 'ngx-markdown';
import { ApiModule } from '@api/api.module';
import { CMSApi } from '@cms/cms-api';
import { IonicModule } from '@ionic/angular';
import { NgxsStoreModule } from '@store/store.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormWrapperModule } from '@forms/form-wrapper/form-wrapper.module';
import { FormWrapperComponent } from '@forms/form-wrapper/form-wrapper.component';
import {
  PhoneValidator,
  BicValidator,
  IbanValidator,
  EmailValidator,
  RequiredValidationMessage,
  MaxlengthValidationMessage,
  MinlengthValidationMessage,
  PhoneValidationMessage,
  EmailValidatorMessage,
  BicValidatorMessage,
  IbanValidatorMessage,
} from '@forms/validations';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeDe, 'de-DE');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
      }),
    ),
    provideClientHydration(
      withHttpTransferCacheOptions({
        filter: (req) => {
          // only requests to cms should be cached
          return req.url.startsWith(environment.cms);
        },
      }),
    ),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideMarkdown(),
    provideHotToastConfig({
      position: 'bottom-center',
      dismissible: true,
      error: { duration: 16000 },
    }),
    provideAnimations(),
    importProvidersFrom(
      ApiModule.forRoot({ rootUrl: environment.nestApi }),
      CMSApi.forRoot({ rootUrl: environment.cms }),
      IonicModule.forRoot({ mode: 'md' }),
      NgxsStoreModule,
      FormlyModule.forRoot({
        // extras: { lazyRender: true, checkExpressionOn: 'changeDetectionCheck' },
        wrappers: [{ name: 'label', component: FormWrapperComponent }],
        validators: [
          {
            name: 'phone',
            validation: PhoneValidator,
          },
          {
            name: 'email',
            validation: EmailValidator,
          },
          { name: 'bic', validation: BicValidator },
          { name: 'iban', validation: IbanValidator },
        ],
        validationMessages: [
          { name: 'required', message: RequiredValidationMessage },
          { name: 'maxLength', message: MaxlengthValidationMessage },
          { name: 'minLength', message: MinlengthValidationMessage },
          { name: 'phone', message: PhoneValidationMessage },
          { name: 'email', message: EmailValidatorMessage },
          { name: 'bic', message: BicValidatorMessage },
          { name: 'iban', message: IbanValidatorMessage },
        ],
      }),
      FormlyMaterialModule,
      FormWrapperModule,
    ),

    // config
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 30000 },
    { provide: MatPaginatorIntl, useClass: PaginatorIntl },
    { provide: MAT_TABS_CONFIG, useValue: { stretchTabs: false } },

    // Sentry
    // error handling
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: !environment.production,
      }),
    },
    // performance
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
