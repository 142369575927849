/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NewPruefer } from '../models/new-pruefer';
import { Pruefer } from '../models/pruefer';

@Injectable({
  providedIn: 'root',
})
export class PrueferService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation pruefersGet
   */
  static readonly PruefersGetPath = '/pruefers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pruefersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  pruefersGet$Response(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<StrictHttpResponse<Array<Pruefer>>> {

    const rb = new RequestBuilder(this.rootUrl, PrueferService.PruefersGetPath, 'get');
    if (params) {
      rb.query('_limit', params['_limit'], {});
      rb.query('_sort', params['_sort'], {});
      rb.query('_start', params['_start'], {});
      rb.query('=', params['='], {});
      rb.query('_ne', params['_ne'], {});
      rb.query('_lt', params['_lt'], {});
      rb.query('_lte', params['_lte'], {});
      rb.query('_gt', params['_gt'], {});
      rb.query('_gte', params['_gte'], {});
      rb.query('_contains', params['_contains'], {});
      rb.query('_containss', params['_containss'], {});
      rb.query('_in', params['_in'], {});
      rb.query('_nin', params['_nin'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Pruefer>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pruefersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pruefersGet(params?: {

    /**
     * Maximum number of results possible
     */
    '_limit'?: number;

    /**
     * Sort according to a specific field.
     */
    '_sort'?: string;

    /**
     * Skip a specific number of entries (especially useful for pagination)
     */
    '_start'?: number;

    /**
     * Get entries that matches exactly your input
     */
    '='?: string;

    /**
     * Get records that are not equals to something
     */
    '_ne'?: string;

    /**
     * Get record that are lower than a value
     */
    '_lt'?: string;

    /**
     * Get records that are lower than or equal to a value
     */
    '_lte'?: string;

    /**
     * Get records that are greater than a value
     */
    '_gt'?: string;

    /**
     * Get records that are greater than  or equal a value
     */
    '_gte'?: string;

    /**
     * Get records that contains a value
     */
    '_contains'?: string;

    /**
     * Get records that contains (case sensitive) a value
     */
    '_containss'?: string;

    /**
     * Get records that matches any value in the array of values
     */
    '_in'?: Array<string>;

    /**
     * Get records that doesn&#x27;t match any value in the array of values
     */
    '_nin'?: Array<string>;
  }): Observable<Array<Pruefer>> {

    return this.pruefersGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Pruefer>>) => r.body as Array<Pruefer>)
    );
  }

  /**
   * Path part for operation pruefersPost
   */
  static readonly PruefersPostPath = '/pruefers';

  /**
   * Create a new record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pruefersPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pruefersPost$Response(params: {
    body: NewPruefer
  }): Observable<StrictHttpResponse<Pruefer>> {

    const rb = new RequestBuilder(this.rootUrl, PrueferService.PruefersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Pruefer>;
      })
    );
  }

  /**
   * Create a new record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pruefersPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pruefersPost(params: {
    body: NewPruefer
  }): Observable<Pruefer> {

    return this.pruefersPost$Response(params).pipe(
      map((r: StrictHttpResponse<Pruefer>) => r.body as Pruefer)
    );
  }

  /**
   * Path part for operation pruefersCountGet
   */
  static readonly PruefersCountGetPath = '/pruefers/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pruefersCountGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  pruefersCountGet$Response(params?: {
  }): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PrueferService.PruefersCountGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pruefersCountGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pruefersCountGet(params?: {
  }): Observable<{
'count'?: number;
}> {

    return this.pruefersCountGet$Response(params).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation pruefersIdGet
   */
  static readonly PruefersIdGetPath = '/pruefers/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pruefersIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  pruefersIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Pruefer>> {

    const rb = new RequestBuilder(this.rootUrl, PrueferService.PruefersIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Pruefer>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pruefersIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pruefersIdGet(params: {
    id: string;
  }): Observable<Pruefer> {

    return this.pruefersIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<Pruefer>) => r.body as Pruefer)
    );
  }

  /**
   * Path part for operation pruefersIdPut
   */
  static readonly PruefersIdPutPath = '/pruefers/{id}';

  /**
   * Update a record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pruefersIdPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pruefersIdPut$Response(params: {
    id: string;
    body: NewPruefer
  }): Observable<StrictHttpResponse<Pruefer>> {

    const rb = new RequestBuilder(this.rootUrl, PrueferService.PruefersIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Pruefer>;
      })
    );
  }

  /**
   * Update a record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pruefersIdPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pruefersIdPut(params: {
    id: string;
    body: NewPruefer
  }): Observable<Pruefer> {

    return this.pruefersIdPut$Response(params).pipe(
      map((r: StrictHttpResponse<Pruefer>) => r.body as Pruefer)
    );
  }

  /**
   * Path part for operation pruefersIdDelete
   */
  static readonly PruefersIdDeletePath = '/pruefers/{id}';

  /**
   * Delete a record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pruefersIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  pruefersIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, PrueferService.PruefersIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Delete a record
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pruefersIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pruefersIdDelete(params: {
    id: string;
  }): Observable<number> {

    return this.pruefersIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
